<template>
    <div class="card shadow mb-3">
		<div class="body-card d-grid">
			<button class="btn rounded-1 justify-content-center btn-outline-theme rounded-0 d-flex gap-1 align-items-center" @click="showModal = true">
				<i class="bi bi-download m-0"></i>
				<p class="m-0">Exportar datos</p>
			</button>

		</div>
    </div>

	<div class="modal fade show" v-if="showModal" style="display:block" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Exportación personalizada</h5>
					<button type="button" class="close btn btn-xl" aria-label="Close" @click="showModal = false">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<section class="border border-2 p-3 border-radius mb-2">
						<p class="mb-2 fw-bold h6">Exportar un producto</p>

						<div class="input-group align-items-center gap-2">
							<label for="product_selector">Producto:</label>
							<select id="product_selector" v-model="productToExport" class="form-select">
								<option value="">Selecciona un producto</option>
								<option :value="product.id" v-for="product in products" :key="product.id"> {{ product.sku }} {{ product.name }}</option>
							</select>
						</div>
					</section>

					<section class="border border-2 p-3 border-radius">
						<p class="mb-2 fw-bold h6">Exportar por fecha:</p>

						<div class="input-group align-items-center gap-2">
							<label for="start_date">Desde:</label>
							<input type="date" v-model="startDate" id="start_date" class="form-control border-0">
						</div>

						<div class="input-group align-items-center gap-2 mb-2">
							<label for="end_date">Hasta:</label>
							<input type="date" v-model="endDate" id="end_date" class="form-control border-0">
						</div>

					</section>
				</div>
				<div class="modal-footer d-block">
					<div class="text-center d-flex gap-3 justify-content-center align-items-center mb-3">
						<button :disabled="isAvailableSubmit" class="btn rounded-1 btn-outline-success rounded-0 d-flex gap-1 align-items-center" @click="handleExport('xls')">
							<i class="bi bi-filetype-xls m-0"></i>
							<p class="m-0">Exportar (excel)</p>
						</button>

						<button :disabled="isAvailableSubmit" class="btn rounded-1 btn-outline-danger rounded-0 d-flex gap-1 align-items-center" @click="handleExport('pdf')">
							<i class="bi bi-filetype-pdf m-0"></i>
							<p class="m-0">Exportar (PDF)</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import server from '@/api/server';
import { onBeforeMount, ref, computed } from 'vue';
import { useProductStore } from "../../stores/product";
import { swalToast } from '@/helpers/swal';
import { forceFileDownload } from '@/helpers/utils.js'

const showModal = ref()
const downloading = ref(false)
const startDate = ref()
const productToExport = ref('')
const endDate = ref()
const products = ref()
const store = useProductStore()
const serverURL = process.env.VUE_APP_SERVER_BASE_URL

const isAvailableSubmit = computed(() => ((!startDate.value && endDate.value) || (startDate.value && !endDate.value)))

onBeforeMount(() => {
	products.value = store.items
})

const submitExportPDF = async() => {
	try {
		const { data } = await server.post('/products/export', {
			start_date: startDate.value, 
			end_date: endDate.value,
			product: productToExport.value
		}, {
			Accept: 'application/pdf',
		})

		if(!data.status)
			return;

		window.open(`${serverURL}/${data.message}`);
	} catch(err) {
		console.log('[ERR] TableComponent.vue', err)
		swalToast(err, "error")
	} finally {
		downloading.value = false
	}
}

const submitExportXLS = async() => {
	try {
		const { data } = await server.post(`/products/export/xlsx`, {
			start_date: startDate.value, 
			end_date: endDate.value,
			product: productToExport.value
		}, { responseType: 'arraybuffer' })

		forceFileDownload(data, `productos`)
	} catch(err) {
		console.log('[ERR] ExportComponent.vue', err)
		swalToast(err, "error")
	}
}

const handleExport = async(type = 'xls') => {
	downloading.value = true

	if(!productToExport.value) {
		return swalToast('Debes seleccionar un producto para iniciar la exportación.', 'error')
	}

	if(isAvailableSubmit.value) {
		return swalToast('Debes seleccionar fechas para una exportación personalizada.', 'error')
	}

	type === 'xls'
		? await submitExportXLS()
		: await submitExportPDF()
	
	showModal.value = false;
	downloading.value = false;
	productToExport.value = '';
	startDate.value = null;
	endDate.value = null;
}
</script>

<style scoped>
div.modal {
	display: flex!important;
    align-items: center;
    justify-content: center;
	background-color: rgb(0 0 0 / 58%);
}

div.modal-dialog {
	max-width: 100%;
	min-width: 400px;
}
</style>